.main-header {
  .logo {
    img {
      max-height: 20px;
    }

    .logo-lg {
      img {
        margin-right: 10px;
      }
    }
  }
}

// Login page
.login-box {
  img {
    max-width: 50px;
  }
}